import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/home';
import LoginPage from "./pages/auth/login";
import RegisterPage from "./pages/auth/register";
import ResetPasswordPage from "./pages/auth/reset-password";
import { Col, Row } from "antd";
import AccountPage from "./pages/account";
import AccountMenu from "./pages/account/components/account-menu";
import AccountHeader from "./pages/account/components/header";
import ParentCreatePage from "./pages/account/parent/parent-create";
import ParentListPage from "./pages/account/parent/parent-list";
import BergeriesCreatePage from "./pages/account/bergeries/bergeries-create";
import BergeriesListPage from "./pages/account/bergeries/bergeries-list";
import LadoumCreatePage from "./pages/account/ladoums/ladoums-create";
import LadoumsListPage from "./pages/account/ladoums/ladoums-list";
import BergeriesPage from "./pages/bergeries/bergeries";
import BergeriesDetailPage from "./pages/bergeries/bergeries-detail";
import PostsPage from "./pages/blog";
import PostDetailPage from "./pages/blog/post-detail";
import ContactPage from "./pages/contact";
import LadoumEditPage from "./pages/account/ladoums/ladoums-edit";
import BergeriesEditPage from "./pages/account/bergeries/bergeries-edit";
import LadoumsDetailPage from "./pages/ladoums/ladoums-detail";
import LadoumsList from "./pages/ladoums/ladoums-list";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/register" element={<RegisterPage />} />
        <Route exact path="/reset-password" element={<ResetPasswordPage />} />
        <Route exact path="/bergeries" element={<BergeriesPage />} />
        <Route exact path="/bergeries/:slug" element={<BergeriesDetailPage />} />
        <Route exact path="/bergeries/ladoum/:slug" element={<LadoumsDetailPage />} />
        <Route exact path="/posts" element={<PostsPage />} />
        <Route exact path="/contact" element={<ContactPage />} />
        <Route exact path="/posts/:slug" element={<PostDetailPage />} />
        <Route exact path="/nos-moutons" element={<LadoumsList />} />

        <Route element={<PrivateWrapper />}>
          <Route path="/account" element={<AccountPage />} />
          <Route path="/account/ladoums-create" element={<LadoumCreatePage />} />
          <Route path="/account/ladoums-list" element={<LadoumsListPage />} />
          <Route path="/account/ladoums/edit/:id" element={<LadoumEditPage />} />
          <Route path="/account/parent-create" element={<ParentCreatePage />} />
          <Route path="/account/parent-list" element={<ParentListPage />} />
          <Route path="/account/bergeries-create" element={<BergeriesCreatePage />} />
          <Route path="/account/bergeries/edit/:id" element={<BergeriesEditPage />} />
          <Route path="/account/bergeries-list" element={<BergeriesListPage />} />
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

const PrivateWrapper = ({ auth }) => {
  return <div className="account-page">
    <AccountHeader />
    <div className="container">
      <Row gutter={24}>
        <Col lg={6} xs={24}>
          <AccountMenu />
        </Col>

        <Col lg={18} xs={24}>
          <Outlet />
        </Col>
      </Row>
      <br /><br />
    </div>
  </div>
};

export default App;
